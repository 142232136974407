import axios from 'axios'
import { Message, Loading } from 'element-ui'
import store from '@/store'
import router from '@/router'
// 创建一个axios的实例

const request = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: 'https://bdb.taoyuer.com',
  timeout: 15000 // request timeout,

})
let loading
// 添加请求拦截器
request.interceptors.request.use(function (config) {
  // 开启全屏loading
  loading = Loading.service({
    lock: true, // 加载期间禁止屏幕滚动
    text: '努力加载中....',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0)'
  })
  // 在发送请求之前携带token
  if (store.getters.token) {
    config.headers.Authorization = `Bearer ${store.getters.token}`
  }

  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 关闭loading
  setTimeout(() => {
    loading.close()
  }, 500)
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  // 关闭loading
  setTimeout(() => {
    loading.close()
  }, 500)
  return response.data
}, function (error) {
  // 关闭loading
  setTimeout(() => {
    loading.close()
  }, 500)
  if (error.response) {
    // console.log(error.response, 999)
    if (error.response.status === 401) {
      Message.warning('身份认证超时,请重新验证')
      // 2.退出
      store.dispatch('user/logout')
      router.push('/login')
      return
    }
    if (error.response.status === 500) {
      Message.error('请联系管理员')
      return
    }
    Message.error(error.response.data.message)
  }
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default request // 导出axios实例
